.Footer {
  width: 100vw;
}
.Footer * {
  box-sizing: border-box;
}

.Footer .footer {
  margin-top: 90px;
}

.searchShortcutsCustomWrapper {
  margin-bottom: var(--gutter-m);
}

.searchShortcutsCustomList::before {
  content: "●";
  color: #c2a360;
  margin-right: 6px;
}

.searchShortcutsCustomListWrapper {
  columns: 2;
}

.searchShortcutsCustomTitle {
  font-size: var(--fontsize-m);
  color: white;
}

.ssFlex {
  display: flex;
  flex-direction: column;
  background-color: red;
}
