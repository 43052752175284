.merchantListWrapper {
  width: 100%;
  max-width: 1290px;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.merchantListArticleWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.merchantListMerchantComponentWrapper {
  padding-top: var(--gutter-xl);
  display: flex;
  flex-direction: column;
  gap: var(--gutter-xl);
}

.merchantListMapComponentWrapper {
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
}

.merchantCardWrapper {
  display: flex;
  justify-content: flex-start;
  gap: var(--gutter-l);
  flex-wrap: wrap;
}

.merchantCardImageDistance {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: var(--secondary-color);
  padding: var(--gutter-m);
  font-size: var(--fontsize-s);
  color: white;
}

.merchantCardImageWrapper {
  width: 310px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.merchantCardImageWrapper img {
  width: 100%;
}

.merchantCardInfosWrapper {
  display: flex;
  gap: var(--gutter-l);
}

.merchantCardInfosText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gutter-l);
}

.merchantCardInfosText h2 {
  letter-spacing: 5px;
  font-size: var(--fontsize-xl);
}

.merchantCardInfosText a {
  color: black;
}

.merchantCardInfosNumberWrapper {
  padding-top: calc(var(--gutter-l) - 6px);
}

.merchantCardInfosNumberTag {
  border: 2px solid var(--secondary-color);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-size: var(--fontsize-s);
}

.merchantCardInfosNumberBar {
  width: 1px;
  height: 70%;
  margin-top: var(--gutter-s);
  background-color: var(--primary-color);
}

.merchantCardInfosNumberBarWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.merchantCardCtas {
  padding: var(--gutter-l);
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--gutter-m);
  flex-direction: column;
}

@media screen and (max-width: 1290px) {
  .merchantListWrapper {
    width: 90%;
  }

  .merchantCardCtas {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .merchantCardImageWrapper {
    width: 100%;
  }
}
