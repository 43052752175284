.homeSearchSearchBarContainer {
  max-width: 1290px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 var(--gutter-l);
}

.homeSearchOurSaloon {
  padding: var(--gutter-xxl);
  font-size: 48px;
  color: #5c184c;
  height: 280px;
  width: 100%;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/20452248546908--orig");
}

.homeSearchSearchBarWrapper {
  background-color: var(--secondary-color);
  width: calc(100% - (50px * 2));
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  gap: var(--gutter-xl);
}

.homeSearchAnchor {
  scroll-margin-top: 164px;
}

.homeSearchSearchBarWrapper h1 {
  font-size: 30px;
  font-family: "Bodini";
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.homeSearchTitleBold {
  font-family: "Bodini";
  font-weight: bold;
}

.homeSearchSearchBarTools {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: var(--gutter-xl);
  flex-wrap: wrap;
}

.homeSearchSearchMapS {
  min-width: 300px;
  width: 50%;
}

@media screen and (max-width: 1290px) {
  .homeSearchSearchBarContainer {
    width: 90%;
  }

  .homeSearchSearchBarWrapper {
    width: calc(100% - (20px * 2));
    padding: 20px;
  }

  .homeSearchOurSaloon {
    font-size: 38px;
    height: 90px;
  }

  .homeSearchAnchor {
    scroll-margin-top: 99px;
  }
}

@media screen and (max-width: 700px) {
  .homeSearchSearchMapS {
    width: 100%;
  }
  .homeSearchOurSaloon {
    font-size: 20px;
    height: 20px;
  }
}
