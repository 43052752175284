.servicesWrapper {
  width: 100%;
}

.servicesWrapper ul {
  background-color: var(--tertiary-color);
  padding: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  list-style: disc;
  max-width: calc(var(--aside-width) - (var(--gutter-m) * 2));
  font-size: var(--fontsize-m);
}

.servicesWrapper li {
  margin-top: var(--gutter-m);
  margin-left: var(--gutter-l);
}

.servicesWrapper h4 {
  margin-bottom: calc(var(--gutter-m) * -1);
  margin-left: var(--gutter-m);
}

@media screen and (max-width: 1200px) {
  .servicesWrapper li {
    font-size: var(--fontsize-s);
    margin: 0;
  }

  .servicesWrapper li:not(:first-child) {
    font-size: var(--fontsize-s);
    margin-top: var(--gutter-s);
  }

  .servicesWrapper {
    width: 100%;
    height: auto;
    background-color: var(--tertiary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--gutter-m);
  }

  .servicesWrapper h4 {
    margin: 0;
  }
}
