.nearbyPlacesWrapperSection {
  background-color: black;
  width: 100%;
  position: relative;
}

.nearbyPlacesWrapperContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  color: white;
  font-size: var(--fontsize-l);
}

.nearbyPlacesWrapperContainer h4 {
  margin: 0 var(--gutter-m) 0 var(--gutter-m);
  font-size: var(--fontsize-m);
}

.nearbyPlacesDetailsWrapper a {
  color: white;
  font-size: var(--fontsize-s);
}

.nearbyPlacesDetailsWrapper li:not(:first-child) {
  margin-top: var(--gutter-l);
}

.nearbyPlacesCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nearbyPlacesDetailsWrapper {
  background-color: black;
  padding: var(--gutter-l);
  width: 190px;
  animation: popInAnim 0.3s;
  z-index: 1;
  color: white;
}

.nearbyPlacesDetailsName {
  margin-bottom: var(--gutter-s);
  font-weight: bold;
}

@keyframes popInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .nearbyPlacesWrapperSection {
    background-color: black;
    width: 90%;
    position: relative;
  }
}
