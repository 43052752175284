.localPageHeaderWrapper {
  background-color: white;
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #eee;
}

.componentWrapper {
  background-color: white;
  padding: var(--gutter-l);
  display: flex;
  position: relative;
}

.componentWrapper:not(:last-child) {
  margin-bottom: var(--gutter-l);
}

.componentWrapper p {
  font-size: 12px;
  text-align: justify;
}

.componentWrapper h3 {
  margin-bottom: var(--gutter-m);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.componentWrapper h3 i {
  margin-right: var(--gutter-m);
}

.componentWrapperFlex {
  flex-direction: column;
  position: relative;
}

.componentItemWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.componentItemWrapper:first-child {
  margin-right: var(--gutter-l);
}

.componentSubItem {
  display: flex;
  width: 100%;
  margin-bottom: var(--gutter-m);
}

.componentSubItem i {
  margin-right: var(--gutter-m);
}

.componentItemWrapperTitle {
  width: 100%;
  font-size: var(--fontsize-s);
}

.localPageHeader {
  background-image: url('//qr28-media.s3.amazonaws.com/brands/5602196670067/contentportal/items/41103374864777--orig-preview');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.localPageCenter {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-xxl);
  max-width: 910px;
}

aside {
  min-width: var(--aside-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gutter-l);
}

.topInfosWrapper {
  display: flex;
  gap: var(--gutter-l);
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 910px;
}

.localPageWrapper {
  width: calc(100% - 40px);
  max-width: 1200px;
  display: flex;
  margin-bottom: 50px;
  margin-top: -50px;
  justify-content: space-between;
  gap: var(--gutter-l);
}

.localPageAppAndPricesMobile {
  display: none;
}

.localPageHardCodedImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: var(--mobile-cube-height);
  border: 5px solid var(--primary-color);
  box-sizing: border-box;
}

.appointmentSmall {
  font-weight: normal;
}

.descriptionWrapper {
}

.descriptionTitle {
  margin-left: var(--gutter-xl);
  margin-bottom: calc(var(--gutter-m) * -1);
}

.descriptionContent {
  background-color: var(--tertiary-color);
  padding: var(--gutter-xl);
  white-space: pre-wrap;
  line-height: 1.2rem;
  font-size: var(--fontsize-m);
}

.mobile-only {
  display: none;
}

.desktop-only {
}

.nearbyPlacesWrapperMobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-only {
    display: block;
  }

  .nearbyPlacesWrapperMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .localPageAppAndPricesMobile {
    display: flex;
    width: 90%;
    gap: var(--gutter-l);
  }

  .desktop-only {
    display: none;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .descriptionTitle {
    margin-left: 0;
    margin-bottom: var(--gutter-s);
    align-self: flex-start;
  }

  .descriptionContent {
    background-color: inherit;
    padding: 0;
  }

  .localPageWrapper {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }

  .localPageHeader {
    height: 135px;
  }

  .topInfosWrapper {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 0 !important;
  }

  .localPageCenter {
    width: 100%;
    align-items: center;
    gap: var(--gutter-l);
  }
}
