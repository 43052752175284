@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap);
:root {
  --primary-color: #c2a360;
  --secondary-color: #b14f4c;
  --tertiary-color: #f8f4ee;
  --borderPrimaryColor: #d9d9d9;
  --background-color: white;
  --text-color-inv: white;
  --gutter-s: 5px;
  --gutter-m: 10px;
  --gutter-l: 20px;
  --gutter-xl: 30px;
  --gutter-xxl: 40px;
  --fontsize-xs: 10px;
  --fontsize-s: 12px;
  --fontsize-m: 14px;
  --fontsize-l: 16px;
  --fontsize-xl: 20px;
  --fontsize-xxl: 24px;
  --wrapper-color-dark: #464545;
  --wrapper-color: #e3e3e3;
  --wrapper-color-m: white;
  --error-color: red;
  --success-color: rgb(59, 200, 57);
  --boxshadow-reg: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  --header-size: 105px;
  --aside-width: 230px;
  --iconsize-xxl: 50px;
  --iconsize-l: 30px;
  --mobile-cube-height: 130px;
}

.sl-flex {
  display: flex;
}

.sl-flex-wrap {
  flex-wrap: wrap;
}

.sl-jc-center {
  justify-content: center;
}

.sl-jc-between {
  justify-content: space-between;
}

.sl-ai-self-center {
  align-self: center;
}

.sl-ai-center {
  align-items: center;
}

.sl-ai-start {
  align-items: flex-start;
}

.sl-ai-end {
  align-items: flex-end;
}

.sl-jc-start {
  justify-content: flex-start;
}

.sl-flex-col {
  flex-direction: column;
}

.sl-gap-s {
  grid-gap: var(--gutter-s);
  gap: var(--gutter-s);
}

.sl-gap {
  grid-gap: var(--gutter-m);
  gap: var(--gutter-m);
}

.sl-gap-l {
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
}

.sl-gap-xl {
  grid-gap: var(--gutter-xl);
  gap: var(--gutter-xl);
}

.sl-gap-xxl {
  grid-gap: var(--gutter-xxl);
  gap: var(--gutter-xxl);
}

.sl-text-secondary {
  color: var(--img-dominant-color);
}

.sl-text-primary {
  color: var(--primary-color);
}

.sl-text-small {
  font-size: var(--fontsize-s);
}

.sl-text-m {
  font-size: var(--fontsize-m);
}

.sl-text-l {
  font-size: var(--fontsize-l);
}

.sl-text-smaller {
  font-size: 0.8em;
}

.sl-text-bold {
  font-weight: bold;
}

.sl-w-100 {
  width: 100%;
}

.sl-h-100 {
  height: 100%;
}

.sl-text-error {
  color: var(--error-color);
}

.sl-text-success {
  color: var(--success-color);
}

.sl-bg-red {
  background-color: red;
}

.sl-bg-blue {
  background-color: blue;
}

.sl-text-center {
  text-align: center;
}

.sl-flex-1 {
  flex: 1 1;
}
@font-face {
  font-family: 'Bodini';
  font-weight: normal;
  src: url(/react-templates/templates/VOG/build/static/media/BauerBodoniStd-Roman.89266405.otf)
    format('opentype');
}

@font-face {
  font-family: 'Bodini';
  font-weight: bold;
  src: url(/react-templates/templates/VOG/build/static/media/BauerBodoniStd-Bold.0b8f72b5.otf)
    format('opentype');
}

@font-face {
  font-family: 'Bodini2';
  src: url(/react-templates/templates/VOG/build/static/media/BauerBodoniStd-Black.37dcb528.otf)
    format('opentype');
}

@font-face {
  font-family: 'GoldenPlains';
  src: url(/react-templates/templates/VOG/build/static/media/GoldenPlains.9f925ae9.otf) format('opentype');
}

@font-face {
  font-family: 'AvantGarde';
  src: url(/react-templates/templates/VOG/build/static/media/ITCAvantGardeStd-Bk.8f14547c.otf)
    format('opentype');
}

@font-face {
  font-family: 'AvantGarde';
  font-weight: bold;
  src: url(/react-templates/templates/VOG/build/static/media/ITCAvantGardeStd-Bold.281d4dd0.otf)
    format('opentype');
}

* {
  font-family: 'AvantGarde';
  box-sizing: content-box;
}

h1 {
  font-family: 'Bodini2';
}

h2 {
  font-family: 'AvantGarde';
  font-weight: bold;
}

body {
  margin: 0;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root {
  overflow-x: hidden;
  height: 100vh;
}

p {
  line-height: 16px;
}
.appWrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.localPageHeaderWrapper {
  background-color: white;
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #eee;
}

.componentWrapper {
  background-color: white;
  padding: var(--gutter-l);
  display: flex;
  position: relative;
}

.componentWrapper:not(:last-child) {
  margin-bottom: var(--gutter-l);
}

.componentWrapper p {
  font-size: 12px;
  text-align: justify;
}

.componentWrapper h3 {
  margin-bottom: var(--gutter-m);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.componentWrapper h3 i {
  margin-right: var(--gutter-m);
}

.componentWrapperFlex {
  flex-direction: column;
  position: relative;
}

.componentItemWrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.componentItemWrapper:first-child {
  margin-right: var(--gutter-l);
}

.componentSubItem {
  display: flex;
  width: 100%;
  margin-bottom: var(--gutter-m);
}

.componentSubItem i {
  margin-right: var(--gutter-m);
}

.componentItemWrapperTitle {
  width: 100%;
  font-size: var(--fontsize-s);
}

.localPageHeader {
  background-image: url('//qr28-media.s3.amazonaws.com/brands/5602196670067/contentportal/items/41103374864777--orig-preview');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.localPageCenter {
  display: flex;
  flex-direction: column;
  grid-gap: var(--gutter-xxl);
  gap: var(--gutter-xxl);
  max-width: 910px;
}

aside {
  min-width: var(--aside-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
}

.topInfosWrapper {
  display: flex;
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 910px;
}

.localPageWrapper {
  width: calc(100% - 40px);
  max-width: 1200px;
  display: flex;
  margin-bottom: 50px;
  margin-top: -50px;
  justify-content: space-between;
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
}

.localPageAppAndPricesMobile {
  display: none;
}

.localPageHardCodedImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: var(--mobile-cube-height);
  border: 5px solid var(--primary-color);
  box-sizing: border-box;
}

.appointmentSmall {
  font-weight: normal;
}

.descriptionWrapper {
}

.descriptionTitle {
  margin-left: var(--gutter-xl);
  margin-bottom: calc(var(--gutter-m) * -1);
}

.descriptionContent {
  background-color: var(--tertiary-color);
  padding: var(--gutter-xl);
  white-space: pre-wrap;
  line-height: 1.2rem;
  font-size: var(--fontsize-m);
}

.mobile-only {
  display: none;
}

.desktop-only {
}

.nearbyPlacesWrapperMobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-only {
    display: block;
  }

  .nearbyPlacesWrapperMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .localPageAppAndPricesMobile {
    display: flex;
    width: 90%;
    grid-gap: var(--gutter-l);
    gap: var(--gutter-l);
  }

  .desktop-only {
    display: none;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .descriptionTitle {
    margin-left: 0;
    margin-bottom: var(--gutter-s);
    align-self: flex-start;
  }

  .descriptionContent {
    background-color: inherit;
    padding: 0;
  }

  .localPageWrapper {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }

  .localPageHeader {
    height: 135px;
  }

  .topInfosWrapper {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 0 !important;
  }

  .localPageCenter {
    width: 100%;
    align-items: center;
    grid-gap: var(--gutter-l);
    gap: var(--gutter-l);
  }
}

.placeInfosWrapper {
  display: flex;
  padding: var(--gutter-l);
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
  background-color: white;
  margin-top: -65px;
}

.placeInfosWrapper h1 {
  font-size: var(--fontsize-xxl);
  margin-bottom: var(--gutter-l);
  max-width: 467px;
}

.placeInfosIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontsize-m);
  grid-gap: 20px;
  gap: 20px;
  color: white;
}

.placeInfosIcon:hover {
  background-color: black;
}

.flexGap {
  display: flex;
  grid-gap: var(--gutter-m);
  gap: var(--gutter-m);
}

.flexGapColumn {
  display: flex;
  grid-gap: var(--gutter-m);
  gap: var(--gutter-m);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.placeInfosMiddle {
  margin-bottom: var(--gutter-l);
}

.placeInfosMiddle p {
  margin-bottom: var(--gutter-m);
}

.placeInfosAddress {
  margin-top: 5px;
  font-style: normal;
}

.placeInfosButtonsMobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .placeInfosWrapper {
    margin-top: 0;
    width: calc(100% - (var(--gutter-l) * 2)) !important;
  }

  .placeInfosMiddle {
    flex-direction: column-reverse;
  }

  .placeInfosWrapper h1 {
    font-size: var(--fontsize-xl);
  }

  .placeInfosButtonsWrapper {
    width: 90%;
    flex-direction: row-reverse;
    grid-gap: var(--gutter-l);
    gap: var(--gutter-l);
  }

  .placeInfosAddress {
    width: 100%;
  }

  .placeInfosButtonsMobile {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.openingHoursWrapper {
  background-color: black;
  color: white;
  padding: var(--gutter-l);
  width: 250px;
  position: relative;
}

.openingHoursWrapper h2 {
  margin-bottom: var(--gutter-m);
}

.openingHoursWrapper hr {
  margin-top: var(--gutter-m);
  margin-top: var(--gutter-m);
}

.showHours {
  cursor: pointer;
  font-size: var(--fontsize-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dayWrapper {
  font-size: var(--fontsize-s);
}

.hourWrapper {
  color: white !important;
}

.hourDisplayerWrapper {
  position: absolute;
  background-color: black;
  padding: var(--gutter-l);
  left: 0;
  width: 250px;
  animation: popInAnim 0.3s;
  z-index: 1;
}

@keyframes popInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .openingHoursWrapper {
    width: calc(90% - (var(--gutter-l) * 2));
  }

  .hourDisplayerWrapper {
    width: calc(100% - (var(--gutter-l) * 2));
  }
}

.simpleCardWrapper {
  background-color: var(--primary-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simpleCardWrapperContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  color: white;
  font-size: var(--fontsize-l);
}

.simpleCardWrapper a {
  color: white;
}

.simpleCardStrong {
  color: white;
  font-weight: bold;
}

.simpleCardWrapperIcon {
  font-size: var(--iconsize-xxl);
  margin-bottom: var(--gutter-xl);
}

.simpleCardTitle {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .simpleCardWrapperIcon {
    font-size: var(--iconsize-l);
    margin-bottom: var(--gutter-xl);
  }
  .simpleCardWrapper {
    height: var(--mobile-cube-height);
  }
}

.servicesWrapper {
  width: 100%;
}

.servicesWrapper ul {
  background-color: var(--tertiary-color);
  padding: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  list-style: disc;
  max-width: calc(var(--aside-width) - (var(--gutter-m) * 2));
  font-size: var(--fontsize-m);
}

.servicesWrapper li {
  margin-top: var(--gutter-m);
  margin-left: var(--gutter-l);
}

.servicesWrapper h4 {
  margin-bottom: calc(var(--gutter-m) * -1);
  margin-left: var(--gutter-m);
}

@media screen and (max-width: 1200px) {
  .servicesWrapper li {
    font-size: var(--fontsize-s);
    margin: 0;
  }

  .servicesWrapper li:not(:first-child) {
    font-size: var(--fontsize-s);
    margin-top: var(--gutter-s);
  }

  .servicesWrapper {
    width: 100%;
    height: auto;
    background-color: var(--tertiary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--gutter-m);
  }

  .servicesWrapper h4 {
    margin: 0;
  }
}

.nearbyPlacesWrapperSection {
  background-color: black;
  width: 100%;
  position: relative;
}

.nearbyPlacesWrapperContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  color: white;
  font-size: var(--fontsize-l);
}

.nearbyPlacesWrapperContainer h4 {
  margin: 0 var(--gutter-m) 0 var(--gutter-m);
  font-size: var(--fontsize-m);
}

.nearbyPlacesDetailsWrapper a {
  color: white;
  font-size: var(--fontsize-s);
}

.nearbyPlacesDetailsWrapper li:not(:first-child) {
  margin-top: var(--gutter-l);
}

.nearbyPlacesCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nearbyPlacesDetailsWrapper {
  background-color: black;
  padding: var(--gutter-l);
  width: 190px;
  animation: popInAnim 0.3s;
  z-index: 1;
  color: white;
}

.nearbyPlacesDetailsName {
  margin-bottom: var(--gutter-s);
  font-weight: bold;
}

@keyframes popInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .nearbyPlacesWrapperSection {
    background-color: black;
    width: 90%;
    position: relative;
  }
}

.merchantListWrapper {
  width: 100%;
  max-width: 1290px;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.merchantListArticleWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.merchantListMerchantComponentWrapper {
  padding-top: var(--gutter-xl);
  display: flex;
  flex-direction: column;
  grid-gap: var(--gutter-xl);
  gap: var(--gutter-xl);
}

.merchantListMapComponentWrapper {
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
}

.merchantCardWrapper {
  display: flex;
  justify-content: flex-start;
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
  flex-wrap: wrap;
}

.merchantCardImageDistance {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: var(--secondary-color);
  padding: var(--gutter-m);
  font-size: var(--fontsize-s);
  color: white;
}

.merchantCardImageWrapper {
  width: 310px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.merchantCardImageWrapper img {
  width: 100%;
}

.merchantCardInfosWrapper {
  display: flex;
  grid-gap: var(--gutter-l);
  gap: var(--gutter-l);
}

.merchantCardInfosText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gutter-l);
}

.merchantCardInfosText h2 {
  letter-spacing: 5px;
  font-size: var(--fontsize-xl);
}

.merchantCardInfosText a {
  color: black;
}

.merchantCardInfosNumberWrapper {
  padding-top: calc(var(--gutter-l) - 6px);
}

.merchantCardInfosNumberTag {
  border: 2px solid var(--secondary-color);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-size: var(--fontsize-s);
}

.merchantCardInfosNumberBar {
  width: 1px;
  height: 70%;
  margin-top: var(--gutter-s);
  background-color: var(--primary-color);
}

.merchantCardInfosNumberBarWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.merchantCardCtas {
  padding: var(--gutter-l);
  margin-left: auto;
  display: flex;
  align-items: center;
  grid-gap: var(--gutter-m);
  gap: var(--gutter-m);
  flex-direction: column;
}

@media screen and (max-width: 1290px) {
  .merchantListWrapper {
    width: 90%;
  }

  .merchantCardCtas {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .merchantCardImageWrapper {
    width: 100%;
  }
}

.homeSearchSearchBarContainer {
  max-width: 1290px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 var(--gutter-l);
}

.homeSearchOurSaloon {
  padding: var(--gutter-xxl);
  font-size: 48px;
  color: #5c184c;
  height: 280px;
  width: 100%;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/20452248546908--orig");
}

.homeSearchSearchBarWrapper {
  background-color: var(--secondary-color);
  width: calc(100% - (50px * 2));
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  grid-gap: var(--gutter-xl);
  gap: var(--gutter-xl);
}

.homeSearchAnchor {
  scroll-margin-top: 164px;
}

.homeSearchSearchBarWrapper h1 {
  font-size: 30px;
  font-family: "Bodini";
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.homeSearchTitleBold {
  font-family: "Bodini";
  font-weight: bold;
}

.homeSearchSearchBarTools {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  grid-gap: var(--gutter-xl);
  gap: var(--gutter-xl);
  flex-wrap: wrap;
}

.homeSearchSearchMapS {
  min-width: 300px;
  width: 50%;
}

@media screen and (max-width: 1290px) {
  .homeSearchSearchBarContainer {
    width: 90%;
  }

  .homeSearchSearchBarWrapper {
    width: calc(100% - (20px * 2));
    padding: 20px;
  }

  .homeSearchOurSaloon {
    font-size: 38px;
    height: 90px;
  }

  .homeSearchAnchor {
    scroll-margin-top: 99px;
  }
}

@media screen and (max-width: 700px) {
  .homeSearchSearchMapS {
    width: 100%;
  }
  .homeSearchOurSaloon {
    font-size: 20px;
    height: 20px;
  }
}

.Header {
  width: 100vw;
}
.Header * {
  box-sizing: border-box;
}

.Footer {
  width: 100vw;
}
.Footer * {
  box-sizing: border-box;
}

.Footer .footer {
  margin-top: 90px;
}

.searchShortcutsCustomWrapper {
  margin-bottom: var(--gutter-m);
}

.searchShortcutsCustomList::before {
  content: "●";
  color: #c2a360;
  margin-right: 6px;
}

.searchShortcutsCustomListWrapper {
  columns: 2;
}

.searchShortcutsCustomTitle {
  font-size: var(--fontsize-m);
  color: white;
}

.ssFlex {
  display: flex;
  flex-direction: column;
  background-color: red;
}

