@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap');
@import './variables.css';

@font-face {
  font-family: 'Bodini';
  font-weight: normal;
  src: url('./fonts/Bauer-Bodoni-Std-1/BauerBodoniStd-Roman.otf')
    format('opentype');
}

@font-face {
  font-family: 'Bodini';
  font-weight: bold;
  src: url('./fonts/Bauer-Bodoni-Std-1/BauerBodoniStd-Bold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Bodini2';
  src: url('./fonts/Bauer-Bodoni-Std-2/BauerBodoniStd-Black.otf')
    format('opentype');
}

@font-face {
  font-family: 'GoldenPlains';
  src: url('./fonts/Golden-Plains/GoldenPlains.otf') format('opentype');
}

@font-face {
  font-family: 'AvantGarde';
  src: url('./fonts/ITC-Avant-Garde-Gothic-Std/ITCAvantGardeStd-Bk.otf')
    format('opentype');
}

@font-face {
  font-family: 'AvantGarde';
  font-weight: bold;
  src: url('./fonts/ITC-Avant-Garde-Gothic-Std/ITCAvantGardeStd-Bold.otf')
    format('opentype');
}

* {
  font-family: 'AvantGarde';
  box-sizing: content-box;
}

h1 {
  font-family: 'Bodini2';
}

h2 {
  font-family: 'AvantGarde';
  font-weight: bold;
}

body {
  margin: 0;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
