.placeInfosWrapper {
  display: flex;
  padding: var(--gutter-l);
  gap: var(--gutter-l);
  background-color: white;
  margin-top: -65px;
}

.placeInfosWrapper h1 {
  font-size: var(--fontsize-xxl);
  margin-bottom: var(--gutter-l);
  max-width: 467px;
}

.placeInfosIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontsize-m);
  gap: 20px;
  color: white;
}

.placeInfosIcon:hover {
  background-color: black;
}

.flexGap {
  display: flex;
  gap: var(--gutter-m);
}

.flexGapColumn {
  display: flex;
  gap: var(--gutter-m);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.placeInfosMiddle {
  margin-bottom: var(--gutter-l);
}

.placeInfosMiddle p {
  margin-bottom: var(--gutter-m);
}

.placeInfosAddress {
  margin-top: 5px;
  font-style: normal;
}

.placeInfosButtonsMobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .placeInfosWrapper {
    margin-top: 0;
    width: calc(100% - (var(--gutter-l) * 2)) !important;
  }

  .placeInfosMiddle {
    flex-direction: column-reverse;
  }

  .placeInfosWrapper h1 {
    font-size: var(--fontsize-xl);
  }

  .placeInfosButtonsWrapper {
    width: 90%;
    flex-direction: row-reverse;
    gap: var(--gutter-l);
  }

  .placeInfosAddress {
    width: 100%;
  }

  .placeInfosButtonsMobile {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
